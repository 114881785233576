<template>
  <div class="event">
    <div class="image">
      <img 
        :src="event.images[Object.keys(event.images)[0]]"
      />
      <div class="place">
        <div class="marker">
          <calendar-icon />
        </div>
        <span class="text">
          {{ startEvent }}
          <!-- {{ event.city.name + (event.place ? ' • '+ event.place.title : '') }} -->
        </span>
      </div>
      <div 
        v-if="event.cost"
        class="badge"
      >
        {{ event.cost }}
      </div>
    </div>
    <div class="datetime">
      {{ event.place ? event.place.title : '' }}
    </div>
    <div class="title">
      {{ event.title }}
    </div>
  </div>
</template>

<script>
import CalendarIcon from "@/components/Icons/Calendar";

export default {
  name: "EventCard",
  components: {
    CalendarIcon,
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    startEvent() {
      let start = this.event.start_at;
      return this.$moment(start).format("LL")
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.event {
  width: 488px;

  .image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 275px;
    border-radius: 16px;
    background: $color_grey_3;
    margin-bottom: 12px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .place {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: baseline;
      width: 100%;
      // min-height: 78px;
      padding-left: 12px;
      padding-top: 38px;
      padding-bottom: 16px;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 0.89;
      letter-spacing: 0.1px;
      color: #fff;
      background: linear-gradient(
        to bottom,
        rgba(#000, 0),
        rgba(#000, 0.81) 88%
      );

      .marker {
        // display: flex;
        // align-items: flex-start;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        margin-top: -4px;
      }
      
      .text {
        line-height: 30px;
      }
    }

    .badge {
      position: absolute;
      top: 16px;
      right: 12px;
      // height: 40px;
      padding: 8px 13px;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.7);
      font-size: 23px;
      font-weight: 500;
      line-height: 1.04;
      color: #ffffff;
    }
  }

  &:active .image {
    transform: scale(0.95);
    transition-duration: 0.3s;
  }

  .title {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -1px;
    color: rgba(#fff, 0.97);
    margin-bottom: 12px;
  }

  .datetime {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.11px;
    color: rgba(166, 166, 166, 0.97);
  }
}
</style>